import { store } from "../store";
import { walletTypes } from "../store/types";
import HttpRequest from "./httpRequests";
import url from "./url";

const { dispatch } = store;
const Request = new HttpRequest();

export const openWalletModal = (value) => {
  return dispatch({ type: walletTypes.OPEN_WALLET_OPTION, payload: value });
};

export const closeWalletModal = () => {
  return dispatch({ type: walletTypes.CLOSE_WALLET_OPTION });
};

export const handleWalletTypeModal = (value) => {
  return dispatch({ type: walletTypes.WALLET_TYPE_MODAL, payload: value });
};

export const closeWalletTypeModal = () => {
  return dispatch({ type: walletTypes.CLOSE_ALL_WALLET_MODAL });
};

export const openTransactionModal = () => {
  return dispatch({ type: walletTypes.OPEN_TRANSACTION_MODAL });
};

export const closeTransactionModal = () => {
  return dispatch({ type: walletTypes.CLOSE_TRANSACTION_MODAL });
};

export const getWalletBalance = async (option, query) => {
  try {
    const walletTransaction = await Request.get(
      `/${url.wallet}/${option}/balance`,
      query
    );
    return Promise.resolve(walletTransaction);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Failed to fetch balance",
    });
  }
};

export const getWalletTransaction = async (query, option) => {
  try {
    const resultResponse = await Request.get(
      `/${url.wallet}/${option}/transaction`,
      query
    );
    return Promise.resolve(resultResponse);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || err.message,
    });
  }
};

export const downloadReportByWalletType = async (query, option) => {
  try {
    const downloadResponse = await Request.post(
      `/${url.wallet}/${option}/download`,
      query
    );
    return Promise.resolve(downloadResponse);
  } catch (error) {
    return Promise.reject({
      message:
        error.response?.data?.message ||
        "Failed to download wallet report",
    });
  }
};

export const downloadReportById = async (id, query, option=  "customer") => {
  try {
    const response = await Request.post(
      `/${url.wallet}/${option}/${id}/download`,
      query
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message:
        error.response?.data?.message ||
        "Failed to download wallet report",
    });
  }
};
