import React, { useState } from "react";
import { Dropdown, Layout } from "antd";
import { LogoutSvg } from "../components/Icons/LogoutIcon";
import { Department } from "../utils/constants";
import { useGetUser } from "../utils/Hooks";

const { Header } = Layout;

const Staff = {
  [Department.BUSINESS]: "Business",
  [Department.FINANCE]: "Finance",
  [Department.SUPPORT]: "Customer Support",
};

const BellSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9997 19C14.9997 20.6569 13.6566 22 11.9997 22C10.3429 22 8.99972 20.6569 8.99972 19M13.7962 6.23856C14.2317 5.78864 14.4997 5.17562 14.4997 4.5C14.4997 3.11929 13.3804 2 11.9997 2C10.619 2 9.49972 3.11929 9.49972 4.5C9.49972 5.17562 9.76772 5.78864 10.2032 6.23856M17.9997 11.2C17.9997 9.82087 17.3676 8.49823 16.2424 7.52304C15.1171 6.54786 13.591 6 11.9997 6C10.4084 6 8.8823 6.54786 7.75708 7.52304C6.63186 8.49823 5.99972 9.82087 5.99972 11.2C5.99972 13.4818 5.43385 15.1506 4.72778 16.3447C3.92306 17.7056 3.5207 18.3861 3.53659 18.5486C3.55476 18.7346 3.58824 18.7933 3.73906 18.9036C3.87089 19 4.53323 19 5.85791 19H18.1415C19.4662 19 20.1286 19 20.2604 18.9036C20.4112 18.7933 20.4447 18.7346 20.4629 18.5486C20.4787 18.3861 20.0764 17.7056 19.2717 16.3447C18.5656 15.1506 17.9997 13.4818 17.9997 11.2Z"
        stroke="#475467"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Notification = () => {
  const [tab, setTab] = useState(1);

  const step = [
    {
      id: 1,
      title: "View All",
      className: "rounded-l-md",
    },
    {
      id: 2,
      title: "Read",
      className: "",
    },
    {
      id: 3,
      title: "Unread",
      className: "rounded-r-md",
    },
  ];

  function handleChange(event) {
    setTab(Number(event.target.value));
  }

  return (
    <Dropdown
      menu={{
        items: [],
      }}
      disabled
      overlayStyle={{
        width: 390,
      }}
      dropdownRender={(menu) => {
        return (
          <div className="p-5 shadow-xl z-[10] bg-white">
            <h3 className="text-[#021230] mb-5 font-bold text-[20px] leading-[30px]">
              Notifications
            </h3>
            {step.map((list) => (
              <button
                onClick={handleChange}
                className={`border border-[#F4F7F9] px-3 py-2 text-[#475467] leading-[20px] ${
                  list.className
                } ${list.id === tab ? "bg-[#F4F7F9] font-bold" : ""}`}
                value={list.id}
              >
                {list.title}
              </button>
            ))}

            <section className="my-4">
              {[1, 2, 3, 2, 0, 3].map(() => (
                <button className="text-left w-full hover:bg-[#F4F7F9] rounded-lg py-3 block text-[#475467] relative">
                  <div className="px-3">
                    <span className="font-[800] leading-[20px]">
                      Vlad Tepes
                    </span>{" "}
                    <span className="font-[500] text-xs leading-[18px]">
                      2 mins ago
                    </span>
                    <p>Downloaded transaction list for Settlement</p>
                    <div className="bg-[#32D583] rounded-xl w-2 h-2 absolute top-4 right-2"></div>
                  </div>
                </button>
              ))}
            </section>
          </div>
        );
      }}
    >
      <button className="p-3">
        <BellSvg />
      </button>
    </Dropdown>
  );
};

const Navbar = ({ logout, className }) => {
  const { user, isEnabled, isUnitSettlement } = useGetUser();
  const staffRole = Staff[user?.department]
    ? `${Staff[user?.department]} - ${user?.role?.replaceAll("_", " ")}`
    : "";

  const getTypeOfBusinessByConfig = () => {
    if (user?.businessType !== "BANKER") return "";

    const label = (() => {
      switch (true) {
        case isUnitSettlement:
          return "Merchant on the go";
        case isEnabled:
          return "Merchant Collection";
        default:
          return "Agency Banking";
      }
    })();

    return `- ${label}`;
  };

  const typeOfBusinessByConfig = getTypeOfBusinessByConfig();

  return (
    <Header className={`mk-nav xl:px-10 ${className}`} theme="light">
      <h2 className="text-2xl font-bold tracking-[0.72px] text-[#021230] capitalize">
        {user?.businessName || staffRole} {typeOfBusinessByConfig}
      </h2>

      <div className="flex items-center gap-4 ml-auto">
        <Notification />

        <button className="p-3" onClick={logout}>
          <LogoutSvg />
        </button>
      </div>
    </Header>
  );
};

export default Navbar;
